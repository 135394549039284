
@media only screen and (max-width: 600px) { 
div.App {
    margin-bottom: 109px;
} 
header {
    height: 60px;
}

/* NAV & FOOTER */
.navigation a span{
    display: none;
}
.navigation {
    position: fixed;
    bottom : 0;
    height : 60px;
    /* width : 100vw; */
    background-color: var(--main-bg-color);
    z-index: 21;
}
.navigation ul {
    display: flex;
    width : 100vw;
    font-size: 25px;
    justify-content: space-around;
    padding-top: 15px;
    list-style: none;
}
.navigation li {
    width : 30px;
    margin: 0; padding: 0;
}

.footer ul.switches {
    position: absolute;
    top: 0;
    right : 0;
    z-index: 21;
    width : 100px;
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}
.footer ul.switches div{
    padding : 0;
}

.footer ul.social {
    position: absolute;
    bottom: -49px;
    z-index: 20;
    width : 100vw;
    display: flex;
    justify-content: space-around;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: var(--main-bg-color);
    
}
.footer a span{
 display: none;
}
/* SWITCHES */
.SwitchDarkLight {
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    width : 32px;
    height: 32px;
    border-radius: 50%;
}
.dark .SwitchDarkLight {
    background-color: #F0DB4F;
    color : #323330;
}
.light .SwitchDarkLight {
    background-color: #323330;
    color : #F0DB4F;
}
.SwitchDarkLight span {
    font-size: 22px !important;
    margin-left: 0 !important;
    /* padding: 0 0 5px 0; */
}

.SwitchLanguage {
    margin: 0;
    padding: 5px 0 30px 0;
}
.SwitchLanguage span {
    margin: 0 !important;
    width: 32px;
    height: 32px;
}
.SwitchLanguage .fr {
    background-position: center;
    background-image: url('./assets/languages/fr.png');
}
.SwitchLanguage .en {
    background-position: center;
    background-image: url('./assets/languages/en.png');
}

}

/* KEYBOARD OPEN PREVENT VISUAL BUG */
@media only screen and (max-height: 380px) and (max-width: 600px){
    .footer > ul.social, .menu > div.navigation {
        display: none;
    }
    section#contact > div.contact {
        height: 100%;
    }
}