/* TITLE */

#hobbies > div.hobbies {
    width : 80%;
    max-width: 1000px;
    margin: 0 auto;
    min-height : 100vh;
}
#hobbies > div.hobbies > h4 {
    position: relative;
    margin-top: 0;
    text-align: center;
    font-size: 36px;
    color: #ebebea;
    text-shadow: 0 1px 0 #1d1e1c, 0 2px 0 #181917, 0 3px 0 #131312, 0 4px 0 #0e0e0d, 0 5px 0 #090908, 0 6px 0 #040403, 0 7px 0 #000, 0 8px 0 #000, 0 0 5px rgba(26, 30, 18, .05), 0 -1px 3px rgba(26, 30, 18, .2), 0 9px 9px rgba(26, 30, 18, .5), 0 12px 12px rgba(26, 30, 18, .5), 0 15px 15px rgba(26, 30, 18, .5);
}
#hobbies > div.hobbies > h4::first-letter {
    color: #f0db4f;
    font-size: 1.2em;  
}
/* HINT */
.hint {
    position: relative;
    text-align: center;
    margin: 0 auto 10px auto;
    width : 200px;
    height: 50px;
    font-weight: bold;
    color : var(--main-text-color);
    background-color: var(--main-bg-color);
    border: solid 3px var(--main-linkSelected-color);
    padding-top : 15px;
    border-radius: 43% 57% 50% 50% / 30% 30% 70% 70%  ;
}


/* <li> BOOKS & GRID */
#hobbies > div.hobbies > ul {
    position: relative;
    display: grid; 
    grid-template-columns: 1fr 6fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "grid1 grid1 ."
      ". grid2 grid2"
      "grid3 grid3 ."
      ". grid4 grid4"
      "grid5 grid5 ." 
      ". grid6 grid6";
       

}
#hobbies > div.hobbies li {
    position: relative;
    border: inset 6px var(--main-linkSelected-color);
    height : 90px;
    overflow : hidden;
    padding : 0 15px;
    color : var(--main-linkSelected-color);
    z-index: 0; 
    cursor : pointer;
    
}
#hobbies > div.hobbies li:hover {
    -webkit-box-shadow:  0px 0px 15px 12px #ffbb00; 
box-shadow:  0px 0px 15px 12px #ffbb00;
}
#hobbies > div.hobbies li:hover::before {
    -webkit-box-shadow: inset 0px 0px 15px 12px #ffbb00; 
box-shadow: inset 0px 0px 15px 12px #ffbb00;
}
#hobbies > div.hobbies li:hover::after {
    -webkit-box-shadow: inset 0px 0px 15px 5px #ffbb00; 
box-shadow: inset 0px 0px 15px 5px #ffbb00;
}
#hobbies > div.hobbies li::before {
    content : " ";
    position : absolute;
    background-color: var(--main-bg-color);
    /* opacity : 0.9; */
    top : 0; bottom: 0; left : 0; right: 0;
    z-index: -1;
    background-color : white;
    background-image : repeating-linear-gradient(to bottom, transparent 6px, #E4E0CE 8px, transparent 8px, transparent 12px); 


}

#hobbies > div.hobbies li::after {
    position : absolute;
    z-index: 2;
    content : " ";
    top: 0; bottom: 0;
    width : 20px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}
.dark #hobbies > div.hobbies li::after {
    background-image : url('../../assets/bg-hobbies.dark.jpg')
}
.light #hobbies > div.hobbies li::after {
    background-image : url('../../assets/bg-hobbies.light.jpg')
}
@media only screen and (min-width: 601px) {
    #hobbies > div.hobbies li::after {
        background-size: 133vw;
    }
}
@media only screen and (max-width: 600px) {

    #hobbies > div.hobbies li::after {
        background-size: 150vh;
    }
    #hobbies > div.hobbies li {
        height : 55px;
    }
}
#hobbies li.grid1, #hobbies li.grid3,#hobbies li.grid4, #hobbies li.grid5 {
    border-right : none !important;

}
#hobbies li.grid1::after, #hobbies li.grid3::after, #hobbies li.grid4::after, #hobbies li.grid5::after {
    right : 0;
    border-radius: 81% 0 0 87%;
    
}
#hobbies li.grid1::before, #hobbies li.grid3::before, #hobbies li.grid4::before, #hobbies li.grid5::before { /* SHADOW*/
    box-shadow: inset 6px 4px 0px 0px #c5c2b6;
    
}

#hobbies li.grid2, #hobbies li.grid6 {
    border-left : none !important;

}
#hobbies li.grid2::after,#hobbies li.grid6::after {
    left : 0;
    border-radius: 0 89% 83% 0;
}
#hobbies li.grid2::before,#hobbies li.grid6::before { /* SHADOW */
    box-shadow: inset -4px 4px 0px 0px #c5c2b6;
}

#hobbies > div.hobbies li.grid1 {
    margin-left: 5%;
    grid-area : grid1;
    border-radius : 80px 0 0 80px;
    border-color: #6b5b95;
    border-left : solid 25px #c94c4c;
}
#hobbies > div.hobbies li.grid1::before {
    border-radius : 30px 0 0 0px
}
#hobbies > div.hobbies li.grid2 {
    margin-right: 10%;
    grid-area: grid2;
    border-radius: 0 12px 15px 0;
    border-color: #feb236;
    border-right : solid 25px #034f84;
}

#hobbies > div.hobbies li.grid3 {
    grid-area: grid3;
    border-radius: 50px 6px 6px 30px;
    border-color: #d64161;
    border-left : solid 25px #86af49;
}
#hobbies > div.hobbies li.grid3::before {
    border-radius: 26px 0 0 0;
}
#hobbies > div.hobbies li.grid4 {
    margin-right: 18%;
    grid-area: grid4;
    border-radius: 6px 0 0 6px;
    border-color: #ff7b25;
    border-left : solid 25px #50394c;
}
#hobbies > div.hobbies li.grid5 {
    margin-left: 6%;
    margin-right: 2%;
    grid-area: grid5;
    border-radius: 20px 0 0 15px;
    border-color: #6b5b95;
    border-left : solid 25px #80ced6;
}
#hobbies > div.hobbies li.grid6 {
    grid-area: grid6;
    border-radius: 0 32px 31px 0;
    margin-bottom: 25px;
    border-color: #a2b9bc;
    border-right : solid 25px #e06377;
}
#hobbies > div.hobbies li.grid6::before {
    border-radius: 0 7px 0 0;
} 
@media only screen and (max-width: 600px) {
   
#hobbies > div.hobbies li.grid1::before, #hobbies > div.hobbies li.grid2::before, #hobbies > div.hobbies li.grid3::before, #hobbies > div.hobbies li.grid4::before, #hobbies > div.hobbies li.grid5::before, #hobbies > div.hobbies li.grid6::before {
    border-radius: 0 0 0 0;
    box-shadow: inset 0px 3px 0px 0px #c7c2af;
}

}

/* BOOKMARKS */

#hobbies li span {
    position: relative;
    top: 0%;
    background-color: #aa0000;
    font-size: 1.2em;
    padding: 2px 5px;
    display: inline-block;
    text-align: center;
    -webkit-box-shadow: 1px 2px 0px 0px rgba(0,0,0,0.6); 
    box-shadow: 1px 2px 0px 0px rgba(0,0,0,0.6);
    color : #f0db4f;

}
#hobbies li span::after, #hobbies li span::before {
    background-color: inherit;
    position: absolute;
    content : "";
    bottom : -15px;
    height: 15px;
    -webkit-box-shadow: 1px 2px 0px 0px rgba(0,0,0,0.6); 
box-shadow: 1px 2px 0px 0px rgba(0,0,0,0.6);  
}
#hobbies li span::before {
    border-radius: 0% 100% 100% 0% / 100% 0% 100% 0% ;
    left: 0; right: 50%;
}
#hobbies li span::after {
    border-radius: 0% 100% 0% 100% / 0% 0% 100% 100%;
    left: 50%; right: 0;
}
#hobbies > div.hobbies li.grid1 span {
    margin-left: 10%;
    margin-top: 2px;
}
#hobbies > div.hobbies li.grid2 span {
    width : 7em;
    margin-left: 45%;
    margin-top: 2px;
}
#hobbies > div.hobbies li.grid3 span {
    margin-left: 33%;
    margin-top: 4px;
}
#hobbies > div.hobbies li.grid4 span {
    margin-left: 32%;
    margin-top: 5px;
}
#hobbies > div.hobbies li.grid5 span {
    width : 7.5em;
    margin-left: 22%;
    margin-top: 1px;
}
#hobbies > div.hobbies li.grid6 span {
    margin-left: 38%;
    margin-top: 0;
}
@media only screen and (min-width: 601px) {
    #hobbies li span {
        font-size: 1em;
        font-family: 'Fuzzy Bubbles', cursive;
    }
}
@media only screen and (max-width: 600px) {
    #hobbies li span {
        font-size: 0.55em;
        font-family: 'Fuzzy Bubbles', cursive;
    }
}