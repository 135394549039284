 
  section div.contact {
    height:100vh;
    /* display: flex;
    justify-content: center;
    align-items:center; */
    -webkit-transition: all 0.6s ease-out;
    -moz-transition: all 0.6s ease-out;
    -o-transition: all 0.6s ease-out;
    transition: all 0.6s ease-out;
    padding-top: 80px;
  }
  .dark section div.contact {
    background-color: #050505;
    border : #F0DB4F;
    color: #F0DB4F;

  }
  .light section div.contact {
    background-color: #ADADAC;
    border: #323330;
    color : #323330;
  }
  
  form {
    max-width: 980px;
    padding-left:25px;
    padding-right:25px;
    padding-top:60px;
    margin: 0 auto;
    border: 2px solid;
    border-color: inherit;   
    position:relative;
    z-index:1;
  }
  .dark form {
    background-color: #050505;
    opacity: 0.8;
  }
  .light form {
      background-color: #FFF;
      opacity: 0.8;
  }
  form input {
      opacity: 1;
  }
  
  form > div{
    font-size: 32px;
    position:absolute;
    top:0;
    left:50%;
    padding:0 20px;
    transform:translate(-50%,-50%);
    border: 2px solid ;
    border-color: inherit;  
    text-align:center;
  }
  @media only screen and (max-width: 600px) { 
    form > div {
      font-size: 18px;
    }
    form  svg {
      display: none !important;
    }
  }
  .dark form > div {
    background-color: #050505;
  }
  .light form > div {
    background-color: #FFF;
  }
  
  form > input,
  form > textarea {
    padding: 12px;
    margin: 12px auto 3px auto;
    border: 1px solid;
    border-color: inherit;
    color: inherit;
    background-color: inherit;  
    display: block;
    width: 90%;
  }
  .dark form > input,
  .dark form > textarea {
    background-color: #2D2E2B;
  }
  .light form > input,
  .light form > textarea {
    background-color: #fcf8dc;
  }
  
  form > textarea{
    height:100px;
    max-width: 100%;
    max-height: 256px; 
  }
  
  form > input[type="submit"] {
    max-width: 20vw;
    padding-left: 1%;
    padding-right: 1%;
    cursor: pointer;
    transition: 0.35s;
    margin-bottom: 18px;
  }
  
  form > input[type="submit"]:hover, form input[type="submit"].sent {
    background-color: var(--main-linkHover-color);
    color: var(--main-bg-color)
  }
  form input[type="submit"].sent {
    cursor:default;
  }

  form > span {
    display: block;
    margin: 0 auto;
    font-style: italic;
    font-size: 0.75em;
    width: 90%;
  }

  form > input.required, form > textarea.required {
    border-color: red;
    color: red;
    transition: all 0.2s ease-in-out;
  }
  
